import { DropdownOption } from "../../components/common/dropdown-input/DropdownInput";

const DAYS = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

export const toDayString = (date: Date) => {
  return DAYS[date.getDay()];
};

export const formatDateToDayAndMonth = (inputDate: Date) => {
  const date = new Date(inputDate);

  const day = date.getDate();
  let month = date.getMonth() + 1;

  month = month < 10 ? '0' + month : month;

  return day + '/' + month;
};

export const formatDateToDayAndMonthAndYear = (inputDate: Date) => {
  const date = new Date(inputDate);

  const day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  month = month < 10 ? '0' + month : month;

  return day + '/' + month + '/' + year;
};

const getTimezoneWithOffset = (date: Date) => {
  const offsetHours = date.getTimezoneOffset() / 60;
  const roundedHours =
    offsetHours % 1 > 0 ? Math.abs(offsetHours).toFixed(2) : Math.abs(offsetHours);

  return `UTC${offsetHours > 0 ? "-" : "+"}${roundedHours}`;
};

export const formatSelfExclusionDate = (date: Date) => {
  // WANT TO RETURN IN FORMAT 12:00 UTC 23/01/2024
  return `${date.getHours()}:${date.getMinutes()} ${getTimezoneWithOffset(
    date,
  )} ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const getMonthlyIntervals = (): DropdownOption[] => {
  const intervals = [];
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  // Get the current date
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Iterate through the current month and the past three months
  for (let i = 0; i < 4; i++) {
    // Calculate the target month and year
    const targetMonth = currentMonth - i;
    const targetYear = currentYear + Math.floor(targetMonth / 12);

    // Adjust month to be within 0-11
    const adjustedMonth = (targetMonth + 12) % 12;

    // Calculate "from" and "to" dates
    const fromDate = new Date(targetYear, adjustedMonth, 15); // 15th of the month
    const toDate = new Date(targetYear, adjustedMonth + 1, 14); // 14th of the next month

    // Generate timestamps
    const fromTimestamp = fromDate.getTime();
    const toTimestamp = toDate.getTime();

    // Create label
    const label = `15 ${monthNames[adjustedMonth]} ${targetYear.toString().slice(-2)} - 14 ${monthNames[toDate.getMonth()]} ${toDate.getFullYear().toString().slice(-2)}`;

    // Add to intervals
    intervals.push({
      key: label,
      value: { from: fromTimestamp / 1000, to: toTimestamp / 1000 },
      label: label,
    });
  }

  return intervals;
};
