import { Connection, PublicKey, SystemProgram, Transaction, TransactionInstruction } from "@solana/web3.js";
import NftStaking, { ISyncMeta } from "./nftStaking";
import StakeReceipt from "./stakeReceipt";
import { Metadata, Metaplex, walletAdapterIdentity, Signer, token } from "@metaplex-foundation/js";
import Trait from "./trait";
import { ICollectionTableRow } from "./collectionTable";
import { AttributeTypes, ExpectancyDirection, IAttribute, StakingStatus, ZeebroCollectionItemProps } from "../../components/zeebros-page/commonComponents";
import { NumberType, formatZeebitNumber } from "../../utils/currency/formatting";
import { BLOCKHASH_COMMITMENT } from "../../utils/solana/rpc";
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { DigitalAssetWithToken, fetchAllDigitalAssetWithTokenByOwner } from "@metaplex-foundation/mpl-token-metadata";
import { publicKey } from "@metaplex-foundation/umi";

BigInt.prototype.toJSON = function () {
    return this.toString();
};

const BASE_URL_FOR_JSON = "https://bafybeifletg2m3ombe46pk36ybgbdkkzkqnqsauxlv2pycelxhx6qmupfa.ipfs.nftstorage.link";

export default class Zeebro {

    private _nftStakingInstance: NftStaking;
    private _state: any;
    private _mintPublicKey: PublicKey;
    private _id: number;
    private _stakeReceipt: StakeReceipt | null;
    private _metaplexMetadata: any | null;
    private _metaplexJson: any | null

    constructor(
        nftStakingInstance: NftStaking,
        mintPubkey: PublicKey,
        index?: number
    ) {
        this._nftStakingInstance = nftStakingInstance;
        this._mintPublicKey = mintPubkey;
        this._id = index != null ? index : this._nftStakingInstance.findNftMintPubkeyInCollectionTable(mintPubkey);
    };

    static async load(
        nftStakingInstance: NftStaking,
        mintPubkey: PublicKey,
        index?: number
    ) {
        const zeebro = new Zeebro(
            nftStakingInstance,
            mintPubkey,
            index
        );

        await zeebro.loadMetaplexMetadata()
        await zeebro.tryLoadStakeReceipt()

        return zeebro;
    }

    async loadMetaplexMetadata() {
        this._metaplexMetadata = await this.fetchSpecificMetaplexMetadata();
    }

    async tryLoadStakeReceipt() {
        this._stakeReceipt = await StakeReceipt.tryLoad(this.nftStakingInstance, this.mintPublicKey);
    }

    static loadFromPrefetched(
        nftStakingInstance: NftStaking,
        mintPubkey: PublicKey,
        metaplexMetadata?: Metadata,
        stakeReceipt?: StakeReceipt,
        index?: number
    ) {
        // Create an instance from pre-fetched information
        const zeebro = new Zeebro(
            nftStakingInstance,
            mintPubkey,
            index
        );
        zeebro._metaplexMetadata = metaplexMetadata;
        zeebro._stakeReceipt = stakeReceipt;
        return zeebro;
    }

    static async loadAllForOwner(
        nftStakingInstance: NftStaking,
        ownerPubkey: PublicKey
    ) {
        const all: Zeebro[] = [];
        const stakeReceipts = await StakeReceipt.getStakeReceiptsForOwner(
            nftStakingInstance,
            ownerPubkey
        );
        const nftsInWallet = await Zeebro.getAllMetadataForOwner(
            nftStakingInstance,
            ownerPubkey
        );

        // TODO - REFACTOR AND MAP INDEX FROM HERE...

        const stakeReceiptsByMint = stakeReceipts.reduce((result, item) => {
            result.set(item.mintPubkey.toString(), item)

            return result
        }, new Map<string, StakeReceipt>())

        for (let i = 0; i < nftsInWallet.length; i++) {
            const metaplexRecord: Metadata = nftsInWallet[i] as Metadata;
            const stakeReceipt = stakeReceiptsByMint.get(metaplexRecord.mint)

            all.push(
                Zeebro.loadFromPrefetched(
                    nftStakingInstance,
                    new PublicKey(metaplexRecord.mint),
                    metaplexRecord,
                    stakeReceipt
                )
            );
        }
        return all;
    }

    static async loadAllNftsInWallet (walletPubkey: string, rpc: Connection, collection: string): Promise<DigitalAssetWithToken[]> {
        const umi = createUmi(rpc.rpcEndpoint, 'processed');
        const owner = publicKey(walletPubkey)
        const fromCollection: DigitalAssetWithToken[] = []
     
        const allNFTs = await fetchAllDigitalAssetWithTokenByOwner(
          umi,
          owner,
        );
     
        allNFTs.forEach((nft, index) => {
            if (nft.metadata.collection?.value?.key == collection) {
                fromCollection.push(nft)
            }
        });

     
       return fromCollection
      
    }

    static async getAllMetadataForOwner(
        nftStakingInstance: NftStaking,
        ownerPubkey: PublicKey
    ) {
        
        const allNfts = await Zeebro.loadAllNftsInWallet(ownerPubkey.toString(), nftStakingInstance.program.provider.connection, nftStakingInstance.collectionPubkey?.toString())
        
        // OLD APPROADH USING METAPLEX API
        // const allOwnerNftMetadatas = await nftStakingInstance.metaplex.nfts().findAllByOwner(args);
        // const filteredOwnerNftMetadatas = allOwnerNftMetadatas.filter(
        //     (n) => (n.collection?.address?.toString() == nftStakingInstance.collectionPubkey?.toString())
        // );
        
        return allNfts.map((token) => {
            return token.metadata
        })
    }

    static async getAllMetadataForCollection(
        nftStakingInstance: NftStaking,
    ) {
        const allCollectionNfts = await nftStakingInstance.metaplex.nfts().findAllByMintList({
            mints: nftStakingInstance.allCollectionMintPubkeys,
        });
        return allCollectionNfts;
    }

    static async fetchSpecificMetaplexMetadata(
        nftStakingInstance: NftStaking,
        nftMintPubkey: PublicKey,
    ) {
        const nftMetadata = await nftStakingInstance.metaplex.nfts().findByMint({
            mintAddress: nftMintPubkey
        });
        return nftMetadata;
    }

    async fetchSpecificMetaplexMetadata() {
        return Zeebro.fetchSpecificMetaplexMetadata(this.nftStakingInstance, this.mintPublicKey)
    }

    setMetaplexData(metplexData: any) {
        this._metaplexMetadata = metplexData
    }

    // LOAD THE NFT JSON FILE (Containing Image etc)
    async loadMetaplexData() {
        if (this._metaplexMetadata.jsonLoaded == true) {
            return this
        }

        this._metaplexMetadata = await this.nftStakingInstance.metaplex.nfts().load({
            metadata: this._metaplexMetadata
        })

        return this
    }
    derivePlayerAccountPubkey(ownerPubkey?: PublicKey): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("player"),
                this.mainState.platform.publicKey.toBuffer(),
                ownerPubkey ? ownerPubkey.toBuffer() : this.program.provider.publicKey.toBuffer(),
            ],
            this.program.programId,
        );
        return pk;
    }
    get nftStakingInstance(): NftStaking {
        return this._nftStakingInstance
    }
    get id(): number {
        return this._id
    }
    get mintPublicKey(): PublicKey {
        return this._mintPublicKey
    }
    get collectionTableRecord(): ICollectionTableRow {
        return this.nftStakingInstance.collectionTable.rows[this.id]
    }

    get isStaked(): boolean {
        return this.stakeReceipt || this.timeStaked ? true : false
    }

    get stakeReceipt(): StakeReceipt | null {
        return this._stakeReceipt
    }
    get metaplexMetadata(): Metadata {
        return this._metaplexMetadata
    }

    get imageUrl(): string {
        return this._metaplexJson?.image || ''
    }


    get jsonUrl(): string {
        return `${BASE_URL_FOR_JSON}/${this.id}.png`
    }

    // Traits
    get backgroundTrait(): Trait {
        return this.nftStakingInstance.getAttributeTraitState(0, this.collectionTableRecord.traits[0])
    }
    get eyesAndAccessoriesTrait(): Trait {
        return this.nftStakingInstance.getAttributeTraitState(1, this.collectionTableRecord.traits[1])
    }
    get headwearTrait(): Trait {
        return this.nftStakingInstance.getAttributeTraitState(2, this.collectionTableRecord.traits[2])
    }
    get clothesTrait(): Trait {
        return this.nftStakingInstance.getAttributeTraitState(3, this.collectionTableRecord.traits[3])
    }
    get mouthTrait(): Trait {
        return this.nftStakingInstance.getAttributeTraitState(4, this.collectionTableRecord.traits[4])
    }
    get currentDistributionTrait(): Trait | null {
        if (this.nftStakingInstance.currentDistribution.selectedAttribute != null) {
            const selectedAttributeId = this.nftStakingInstance.currentDistribution.selectedAttribute;
            switch (selectedAttributeId) {
                case 0:
                    return this.backgroundTrait
                case 1:
                    return this.eyesAndAccessoriesTrait
                case 2:
                    return this.headwearTrait
                case 3:
                    return this.clothesTrait
                case 4:
                    return this.mouthTrait
            };
        } else {
            return null
        }

        return null
    }

    // From CollectionTable Record
    get balanceToCollect() {
        return Number(this.collectionTableRecord.balanceToCollect)
    }

    get balanceToCollectUI() {
        return this.balanceToCollect / Math.pow(10, this.nftStakingInstance.tokenDecimals)
    }

    get timeStaked() {
        return this.collectionTableRecord.timeStaked != null && Number(this.collectionTableRecord.timeStaked) > 0 ? new Date(Number(this.collectionTableRecord.timeStaked) * 1000) : null
    }

    get daysSinceStaking() {
        const timeStaked = this.timeStaked

        if (timeStaked != null) {
            const currentDate = new Date()
            const msBetween = currentDate.getTime() - timeStaked.getTime()
            const MS_IN_DAY = 1000 * 3600 * 24

            return Math.round(msBetween / MS_IN_DAY)
        } else {
            return 0
        }
    }

    get allTimeCountIndividualWinsFomo() {
        return this.collectionTableRecord.countIndividualWinsTotal
    }
    get allTimeCountIndividualWinsStaked() {
        return this.collectionTableRecord.countIndividualWinsStaked
    }

    get allTimeCountIndividualWins() {
        return this.allTimeCountIndividualWinsFomo
    }

    get allTimeSumIndividualWinningsFomo(): number {
        return Number(this.collectionTableRecord.sumIndividualWinningsFomo) / (10 ** this.nftStakingInstance.tokenDecimals)
    }

    get allTimeSumIndividualWinningsStaked(): number {
        return Number(this.collectionTableRecord.sumIndividualWinningsStaked) / (10 ** this.nftStakingInstance.tokenDecimals)
    }

    get allTimeSumTraitWinningsFomo(): number {
        return Number(this.collectionTableRecord.sumTraitWinningsFomo) / (10 ** this.nftStakingInstance.tokenDecimals)
    }
    get allTimeSumTraitWinningsStaked(): number {
        return Number(this.collectionTableRecord.sumTraitWinningsStaked) / (10 ** this.nftStakingInstance.tokenDecimals)
    }

    get allTimeSumTotalWinningsStaked(): number {
        return this.allTimeSumTraitWinningsStaked + this.allTimeSumIndividualWinningsStaked
    }

    get allTimeSumTotalFomo(): number {
        return this.allTimeSumIndividualWinningsFomo + this.allTimeSumTraitWinningsFomo
    }

    get allTimeSumTotalMissed(): number {
        return this.allTimeSumTotalFomo - this.allTimeSumTotalWinningsStaked
    }

    // From Traits
    get allTimeCountTraitWins(): number {
        return (
            this.backgroundTrait.countTimesSelected +
            this.eyesAndAccessoriesTrait.countTimesSelected +
            this.headwearTrait.countTimesSelected +
            this.clothesTrait.countTimesSelected +
            this.mouthTrait.countTimesSelected
        )
    }

    get allTimeCountTotalWins(): number {
        return this.allTimeCountTraitWins + this.allTimeCountIndividualWins
    }

    // From StakeReceipt
    get balanceDrawndown(): number | null {
        return this.stakeReceipt ? this.stakeReceipt.balanceDrawndown / Math.pow(10, this.nftStakingInstance.tokenDecimals) : null
    }
    get avatarApplied(): boolean | null {
        return this.stakeReceipt ? (this.stakeReceipt.avatarApplied ? true : false) : null
    }
    get rakebackBoostApplied(): boolean | null {
        return this.stakeReceipt ? (this.stakeReceipt.rakebackBoostApplied ? true : false) : null
    }

    get status(): StakingStatus {
        // TODO - GET OTHER STATUSES
        if (this.isStaked == true) {
            return StakingStatus.Staked
        } else {
            return StakingStatus.Unstaked
        }
    }

    // TODO - MOVE TO CONFIG FOR 5% BELOW
    get expectancyDirection(): ExpectancyDirection {
        // TODO - MAP EXPECTANCY DIRECTION
        const expectancyPercentage = this.expectancyDifference * 100

        if (expectancyPercentage > 0) {
            return expectancyPercentage > 5 ? ExpectancyDirection.UpLots : ExpectancyDirection.Up
        } else if (expectancyPercentage < 0) {
            return expectancyPercentage < 5 ? ExpectancyDirection.DownLots : ExpectancyDirection.Down
        }

        return ExpectancyDirection.None
    }

    // NEW EXPECTANCY VALUES

    // CASE WHERE ATTRIBUTE SELECTED
    get chanceTraitSelectedAfterAttributeSelected() {
        if (this.currentDistributionTrait == null) {
            return
        }

        return this.currentDistributionTrait.chanceTraitSelected
    }

    get expectedTraitWinningsAttributeSelected() {
        if (this.nftStakingInstance.currentDistribution == null || this.chanceTraitSelectedAfterAttributeSelected == null || this.currentDistributionTrait == null) {
            return 0
        }

        return (this.nftStakingInstance.currentDistribution.traitSelectedPrize * this.chanceTraitSelectedAfterAttributeSelected) / Math.max(this.currentDistributionTrait.countStaked, 1)
    }

    get expectedJackpotWinnings() {
        if (this.nftStakingInstance.currentDistribution == null) {
            return 0
        }

        return this.nftStakingInstance.currentDistribution.jackpotPrize / this.nftStakingInstance.countPopulation
    }

    get expectedValueAttributeSelected() {
        return this.expectedTraitWinningsAttributeSelected + this.expectedJackpotWinnings
    }

    get expectedValueDollarAttributeSelected() {
        return this.expectedValueAttributeSelected / Math.pow(10, this.nftStakingInstance.tokenDecimals)
    }

    // CASE WHERE ATTRIBUTE NOT SELECTED
    get expectedTraitWinningAttributeNotSelected() {
        if (this.nftStakingInstance?.currentDistribution?.traitSelectedPrize == null || this.nftStakingInstance.currentDistribution.traitSelectedPrize <= 0) {
            return 0
        }

        const traits = [this.backgroundTrait, this.clothesTrait, this.mouthTrait, this.eyesAndAccessoriesTrait, this.headwearTrait]
        const numberTraits = traits.length

        return traits.reduce((result, item) => {
            result += ((this.nftStakingInstance.currentDistribution.traitSelectedPrize * item.chanceTraitSelected) / Math.max(item.countStaked, 1)) / numberTraits

            return result
        }, 0)
    }

    get expectedValueAttributeNotSelected() {
        return this.expectedTraitWinningAttributeNotSelected + this.expectedJackpotWinnings
    }

    get expectedValueAttributeNotSelectedDollar() {
        return this.expectedValueAttributeNotSelected / Math.pow(10, this.nftStakingInstance.tokenDecimals)
    }

    get expectedValue() {
        return this.currentDistributionTrait != null ? this.expectedValueAttributeSelected : this.expectedValueAttributeNotSelected
    }

    get expectedValueDollar() {
        return this.expectedValue / Math.pow(10, this.nftStakingInstance.tokenDecimals)
    }

    get expectancyDifference() {
        if (this.nftStakingInstance?.averageZeebroExpectancy?.basis == null || this.nftStakingInstance.averageZeebroExpectancy.basis <= 0) {
            return 0
        }

        // (Expectancy (pre/post attribute selection, depending on time) / baseline) - 1) * 100 [to give the % value]
        return (this.expectedValue / this.nftStakingInstance.averageZeebroExpectancy.basis) - 1
    }

    // END NEW EXPECTANCIES

    get unstakingPenaltyPercentageOfFloor() {
        return this._nftStakingInstance.mainState.unstakingPenaltyPerThousand / 1000
    }

    get unstakingPenaltySol() {
        return this.nftStakingInstance.floorPriceSol * this.unstakingPenaltyPercentageOfFloor
    }

    get unstakingPenaltyToken() {
        return this.nftStakingInstance.floorPriceToken * this.unstakingPenaltyPercentageOfFloor
    }

    get rakebackBoostAvailable() {
        return (this.eyesAndAccessoriesTrait?.state?.properties[0] || 0) / 1000
    }

    static getLocalTraitImageForAttribute(attributeId: number, traitId: number) {
        return Trait.getImageUrl(attributeId, traitId)
    }

    getLocalTraitImage(attribute: AttributeTypes) {
        switch (attribute) {
            case AttributeTypes["Eyes & Accessories"]:
                return Trait.getImageUrl(attribute, this.eyesAndAccessoriesTrait.id)
            case AttributeTypes.Background:
                return Trait.getImageUrl(attribute, this.backgroundTrait.id)
            case AttributeTypes.Clothing:
                return Trait.getImageUrl(attribute, this.clothesTrait.id)
            case AttributeTypes.Headwear:
                return Trait.getImageUrl(attribute, this.headwearTrait.id)
            case AttributeTypes.Mouth:
                return Trait.getImageUrl(attribute, this.mouthTrait.id)
        }
    }

    async getStakeTx(
        owner: PublicKey,
        initPlayerIx?: TransactionInstruction | undefined
    ) {
        return await this.nftStakingInstance.getStakeTx(
            owner,
            this.mintPublicKey,
            initPlayerIx
        )
    }

    async getSyncTx(
        player: PublicKey = SystemProgram.programId,
        owner: PublicKey,
        applyRakebackBoost: boolean,
        applyAvatar: boolean
    ) {
        return await this.nftStakingInstance.getSyncTx(
            player,
            owner,
            this.mintPublicKey,
            applyRakebackBoost,
            applyAvatar
        )
    }

    async getUnstakeTx(
        owner: PublicKey
    ) {


        return await this.nftStakingInstance.getUnStakeTx(
            owner,
            this.mintPublicKey
        )
    }

    async getTransferTx(currentOwnerPubkey: PublicKey, newOwnerPubkey: PublicKey, client: Connection): Promise<Transaction> {
        const feePayer: Signer = {
            publicKey: currentOwnerPubkey,
            signTransaction: async (tx) => tx,
            signMessage: async (msg) => msg,
            signAllTransactions: async (txs) => txs,
        };

        const metaplex: Metaplex = this.nftStakingInstance.metaplex;

        metaplex.use(walletAdapterIdentity({
            publicKey: currentOwnerPubkey,
            signTransaction: async (tx) => tx,
        }));

        const nft = await metaplex.nfts().findByMint({ mintAddress: this.mintPublicKey });

        const txBuilder = metaplex.nfts().builders().transfer({
            nftOrSft: nft,
            fromOwner: currentOwnerPubkey,
            toOwner: newOwnerPubkey,
            amount: token(1),
            authority: feePayer,
        });

        const blockhash = await client.getLatestBlockhash(BLOCKHASH_COMMITMENT);

        return txBuilder.toTransaction(blockhash);
    }

    setMetaplexJson(metplexJson: any) {
        this._metaplexJson = metplexJson

        return this
    }

    get formattedValues(): ZeebroCollectionItemProps {
        return {
            id: this.id,
            rakback: this.rakebackBoostAvailable,
            status: this.status,
            nftImageUrl: this.imageUrl,
            title: this.name,
            winnings: formatZeebitNumber(this.allTimeSumTotalFomo, NumberType.DOLLAR_AMOUNT),
            missed: formatZeebitNumber(this.allTimeSumTotalMissed, NumberType.DOLLAR_AMOUNT),
            nominated: this.currentDistributionTrait?.name,
            traitStaked: this.currentDistributionTrait?.countStaked,
            totalWithTrait: this.currentDistributionTrait?.countPopulation,
            expectancy: this.expectedValueDollar, // TODO
            expectancyDifference: formatZeebitNumber(this.expectancyDifference, NumberType.PROBABILITY),
            expectancyDirection: this.expectancyDirection,
            mintPubkey: this.mintPublicKey,
            rakebackRate: formatZeebitNumber(this.rakebackBoostAvailable, NumberType.PROBABILITY)
        }
    }

    get name() {
        return `Zeebro #${this.id}`
    }

    get formattedAttributes(): IAttribute[] {
        return [
            {
                id: this.eyesAndAccessoriesTrait.id,
                type: AttributeTypes["Eyes & Accessories"],
                name: this.eyesAndAccessoriesTrait.name,
                rarity: formatZeebitNumber(this?.eyesAndAccessoriesTrait.proportionPopulation,
                    NumberType.PROBABILITY,
                ),
                url: this?.getLocalTraitImage(AttributeTypes["Eyes & Accessories"]),
                staked: formatZeebitNumber(
                    this?.eyesAndAccessoriesTrait.proportionStaked,
                    NumberType.PROBABILITY,
                ),
            },
            {
                id: this?.backgroundTrait.id,
                type: AttributeTypes.Background,
                name: this.backgroundTrait.name,
                rarity: formatZeebitNumber(
                    this?.backgroundTrait.proportionPopulation,
                    NumberType.PROBABILITY,
                ),
                url: this?.getLocalTraitImage(AttributeTypes.Background),
                staked: formatZeebitNumber(
                    this?.backgroundTrait.proportionStakedOutOfPopulation,
                    NumberType.PROBABILITY,
                ),
            },
            {
                id: this?.clothesTrait.id,
                type: AttributeTypes.Clothing,
                name: this.clothesTrait.name,
                rarity: formatZeebitNumber(
                    this?.clothesTrait.proportionPopulation,
                    NumberType.PROBABILITY,
                ),
                url: this?.getLocalTraitImage(AttributeTypes.Clothing),
                staked: formatZeebitNumber(
                    this?.clothesTrait.proportionStakedOutOfPopulation,
                    NumberType.PROBABILITY,
                ),
            },
            {
                id: this?.headwearTrait.id,
                type: AttributeTypes.Headwear,
                name: this.headwearTrait.name,
                rarity: formatZeebitNumber(
                    this?.headwearTrait.proportionPopulation,
                    NumberType.PROBABILITY,
                ),
                url: this?.getLocalTraitImage(AttributeTypes.Headwear),
                staked: formatZeebitNumber(
                    this?.headwearTrait.proportionStakedOutOfPopulation,
                    NumberType.PROBABILITY,
                ),
            },
            {
                id: this?.mouthTrait.id,
                type: AttributeTypes.Mouth,
                name: this.mouthTrait.name,
                rarity: formatZeebitNumber(
                    this?.mouthTrait.proportionPopulation,
                    NumberType.PROBABILITY,
                ),
                url: this?.getLocalTraitImage(AttributeTypes.Mouth),
                staked: formatZeebitNumber(
                    this?.mouthTrait.proportionStakedOutOfPopulation,
                    NumberType.PROBABILITY,
                ),
            },
        ];
    }
}

