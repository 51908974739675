import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PublicKey } from "@solana/web3.js";
import SolanaRpc, { ISolanaRpc } from "../utils/solana/rpc";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { Wallet, useWallet } from "@solana/wallet-adapter-react";
import NonSocialSolanaRpc from "../utils/solana/rpc-non-social";
import { NetworkContext } from "./NetworkContext";
import { SocialWalletContext } from "./SocialWalletContext";
import { WalletName } from "@solana/wallet-adapter-base";
import { getRpcWriteEndpoints } from "../utils/env/env";
import { APP_NETWORK_TYPE } from "../types/chain";
import { getEnabledWalletsForChain } from "../constants/web3Wallet";
import { Wallets } from "../constants/wallets";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { sendWalletConnected } from "../utils/analytics/google-analytics";
import { pubkeyListMatchV2 } from "@metaplex-foundation/mpl-token-auth-rules";

export interface IWrappedWalletContext {
  // SOLANA
  loggedIn: boolean;
  connect: (wallet: string, provider?: string) => Promise<void>;
  disconnect: () => Promise<void>;
  solanaRpc: ISolanaRpc | undefined;
  walletPubkey: PublicKey | null;
  wallets: Wallet[];
  select: (walletName: WalletName | null) => void;
  wallet: Wallet | null;
  connected: boolean;
  connecting: boolean;
  isWeb3AuthWallet: boolean;
  selectedWalletName: Wallets | null;
  setSelectedWalletName: Function;
  walletUptoDateWithChain: boolean
}

export const WrappedWalletContext = createContext<IWrappedWalletContext>(
  {} as IWrappedWalletContext
);

interface Props {
  children: any;
}

export const WrappedWalletProvider = ({ children }: Props) => {
  const { walletValidation } = useContext(ErrorHandlingContext);

  const [selectedWalletName, setSelectedWalletName] = useLocalStorage("zeebit-connected-wallet-name", null);
  
  // FLAG ON CHAIN UPDATE
  const [walletUptoDateWithChain, setWalletUptoDateWithChain] = useState<boolean>(false)
  const [loadedChain, setLoadedChain] = useState<APP_NETWORK_TYPE>()

  // WANT TO SWITCH HERE WEB3 AUTH OR REGULAR WALLET ADAPTER...
  const socialWallet = useContext(SocialWalletContext);
  const nonSocialWallet = useWallet();

  const publicKey = useMemo(() => {
    if (nonSocialWallet.publicKey != null) {
      return nonSocialWallet.publicKey;
    } else if (socialWallet.walletPubkey != null) {
      return socialWallet.walletPubkey;
    } else if (nonSocialWallet.wallet?.adapter.publicKey != null) {
      return nonSocialWallet.wallet?.adapter.publicKey
    } else {
      return null;
    }
  }, [nonSocialWallet, socialWallet]);

  useEffect(() => {
    // WALLET CONNECTED EVENT TO GA
    if (publicKey != null) {
      sendWalletConnected({
        publicKey: publicKey.toString()
      })
    }
  }, [publicKey])

  const isWeb3AuthWallet = useMemo(() => {
    return (
      socialWallet.walletPubkey != null && nonSocialWallet.publicKey == null
    );
  }, [socialWallet, nonSocialWallet]);

  const disconnect = useCallback(async (): Promise<void> => {
    const promises = []
    setSelectedWalletName(null);

    if (
      (nonSocialWallet.publicKey || nonSocialWallet.wallet?.adapter.publicKey) != null &&
      (nonSocialWallet.disconnect || nonSocialWallet.wallet?.adapter.disconnect) != null
    ) {
      // sonicLogout();
      const disconnectFx = nonSocialWallet.disconnect || nonSocialWallet.wallet?.adapter.disconnect

      promises.push(disconnectFx());
    }
     
    if (
      socialWallet.walletPubkey != null &&
      socialWallet.logoutSocial != null
    ) {
      promises.push(socialWallet.logoutSocial());
    }

    if (promises.length > 0) {
      await Promise.all(promises)
      return
    }

    return Promise.reject("Neither the wallet or social wallet are connected.");
  }, [nonSocialWallet, socialWallet]);

  const connect = useCallback(
    async (walletName?: string, provider?: string): Promise<void> => {
      if (
        socialWallet.loginSocial != null &&
        walletName != null &&
        provider != null
      ) {
        setSelectedWalletName(provider as Wallets);

        return await socialWallet.loginSocial(walletName, provider.toLowerCase());
      } else if ((nonSocialWallet.connect || nonSocialWallet.wallet?.adapter.connect) != null) {
        setSelectedWalletName(walletName as Wallets);

        if (nonSocialWallet.wallet?.adapter.name != null) {
          return await nonSocialWallet.wallet?.adapter.connect();
        }

        return await nonSocialWallet.connect();
      }

      setSelectedWalletName(null);

      return Promise.reject(
        "Neither the wallet or social wallet are connected."
      );
    },
    [socialWallet, nonSocialWallet]
  );

  const connected = useMemo(() => {
    return socialWallet.loggedIn || (nonSocialWallet.connected || nonSocialWallet.wallet?.adapter.connected);
  }, [socialWallet.loggedIn, nonSocialWallet.connected, nonSocialWallet.wallet?.adapter.connected]);

  // CHECK WALLET CONNECTED - Change label of button and disable button (Connect to bet)
  useEffect(() => {
    if (
      publicKey == null
    ) {
      walletValidation.addErrorMessage({
        type: ErrorType.WALLET_NOT_CONNECTED,
        title: "Wallet Not Connected",
        message: "You must connect your wallet place a bet.",
      });
    } else {
      walletValidation.removeErrorMessage(ErrorType.WALLET_NOT_CONNECTED);
    }
  }, [publicKey]);

  const { client, chain, uptoDateWithChain } = useContext(NetworkContext);
  const [rpc, setRpc] = useState<ISolanaRpc>()

  useEffect(() => {
    if (uptoDateWithChain == false) {
      return
    }

    if (socialWallet.walletPubkey != null && socialWallet.solanaRpc != null) {
      setRpc(socialWallet.solanaRpc);
      setLoadedChain(chain)
    } else if ((nonSocialWallet.publicKey != null || nonSocialWallet.wallet?.adapter.publicKey != null) && client != null) {
      // WANT TO RETURN THE RPC CLASS
      const writeEndpoints = getRpcWriteEndpoints(chain);

      setRpc(new NonSocialSolanaRpc(
        nonSocialWallet,
        client,
        writeEndpoints || []
      ))
      setLoadedChain(chain)
    }
  }, [socialWallet.walletPubkey, nonSocialWallet.publicKey, nonSocialWallet.wallet?.adapter.publicKey, uptoDateWithChain]);

  useEffect(() => {
    if (nonSocialWallet.wallet?.adapter.name) {
      connect(nonSocialWallet.wallet?.adapter.name)
        .then((resp) => {
          console.log("Successfully connected to the wallet!", resp);
        })
        .catch((e) => {
          console.error("Error connecting to the wallet...", e);
        });
    }
  }, [nonSocialWallet.wallet?.adapter.name]);

  // CHECK IF THE WALLET IS ENABLED WHEN CHAIN CHANGES
  useEffect(() => {
    async function disconnectIfNotEnabled(
      chain: APP_NETWORK_TYPE,
      walletName: string
    ) {
      const enabledWallets = getEnabledWalletsForChain(chain);

      if (!enabledWallets?.includes(walletName)) {
        console.warn(`Disconnecting as wallet not connected`);
        await disconnect();
      }
    }

    if (
      chain != null &&
      nonSocialWallet.wallet?.adapter.name &&
      connected == true
    ) {
      disconnectIfNotEnabled(chain, nonSocialWallet.wallet?.adapter.name);
    }
  }, [chain, nonSocialWallet.wallet?.adapter.name, connected, disconnect]);

  useEffect(() => {
    if (chain != loadedChain && walletUptoDateWithChain == true) {
      setWalletUptoDateWithChain(false)
    } else if (chain == loadedChain && walletUptoDateWithChain == false) {
      setWalletUptoDateWithChain(true)
    }
  }, [chain, rpc, loadedChain])

  return (
    <WrappedWalletContext.Provider
      value={useMemo(
        () => ({
          loggedIn: connected,
          solanaRpc: rpc,
          walletPubkey: publicKey,
          wallets: nonSocialWallet.wallets,
          select: nonSocialWallet.select,
          wallet: nonSocialWallet.wallet,
          disconnect: disconnect,
          connect: connect,
          connected: connected,
          connecting: nonSocialWallet.connecting,
          isWeb3AuthWallet: isWeb3AuthWallet,
          selectedWalletName: selectedWalletName,
          setSelectedWalletName: setSelectedWalletName,
          walletUptoDateWithChain: walletUptoDateWithChain
        }),
        [
          connect,
          disconnect,
          rpc,
          publicKey,
          connected,
          nonSocialWallet.wallets,
          nonSocialWallet.select,
          nonSocialWallet.wallet,
          nonSocialWallet.connecting,
          isWeb3AuthWallet,
          selectedWalletName,
          setSelectedWalletName,
          walletUptoDateWithChain
        ]
      )}
    >
      {children}
    </WrappedWalletContext.Provider>
  );
};
