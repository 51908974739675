import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Tabs } from "../referrals/Tabs";
import { DataCachingContext } from "../../contexts/DataCachingContext";
import { IBetHistory, ITransactionHistory } from "../../utils/supabase/types";
import { WrappedWalletContext } from "../../contexts";
import { PublicKey } from "@solana/web3.js";
import { ISolanaRpc } from "../../utils/solana/rpc";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { HomepagePreloader } from "../../pages/preloaders/HomepagePreloader";

export enum TxnTab {
  TRANSACTIONS = "Transactions",
  BETS = "Bets"
}

export const BettingHistory = () => {
  const [selectedTab, setSelectedTab] = useLocalStorage('zeebit-txn-tab', TxnTab.TRANSACTIONS);
  
  const [bets, setBets] = useState<IBetHistory[]>() 
  const [transactions, setTransactions] = useState<ITransactionHistory[]>() 
  const deposits = useMemo(() => {
    return transactions?.filter((txn) => {
        return txn.isDeposit == true
    })
  }, [transactions])

  const withdrawals = useMemo(() => {
    return transactions?.filter((txn) => {
        return txn.isDeposit == false
    })
  }, [transactions])

  // ILIA HERE ARE THE METHODS TO LOAD THE BETS + transactionHistory (which is deposits and withdrawals)
  const { loadBets, loadTransactionHistory } = useContext(DataCachingContext);

  const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext)
  const walletBetsLoaded = useRef<string>()

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadMeta(wallet: PublicKey, rpc: ISolanaRpc) {
      try {
        setLoading(true)
        const updatedBets = await loadBets(wallet, rpc)
        const updatedTxns = await loadTransactionHistory(wallet, rpc)

        console.log({
            updatedBets,
            updatedTxns
        })

        setBets(updatedBets)
        setTransactions(updatedTxns)
      } catch (err) {
        console.warn(`Err loading referrals`, {
          err
        })
      } finally {
        setLoading(false)
      }
    }

    if (walletPubkey == null || solanaRpc == null) {
      setBets(undefined)
      setTransactions(undefined)

      walletBetsLoaded.current = undefined
      return
    } else if (walletBetsLoaded.current != walletPubkey.toString()) {
      walletBetsLoaded.current = walletPubkey.toString()
      loadMeta(walletPubkey, solanaRpc)
    }
  }, [walletPubkey, loadBets, solanaRpc, loadTransactionHistory])


  return (
    <div className="flex w-full p-0 flex-col items-start gap-y-5 overflow-x-hidden">
      {loading ? <HomepagePreloader /> : <>
        <div>There are {bets?.length} bets</div>
        <div>There are {deposits?.length} deposits</div>
        <div>There are {withdrawals?.length} withdrawals</div>
      </>}
    </div>
  );
};
