import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { Connection } from "@solana/web3.js";
import {
  APP_NETWORK_TYPE,
  NetworkType,
  defaultNetworkType,
} from "../types/chain";
import { defaultNetwork } from "../utils/chain/network";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {
  APP_NETWORK,
  ENV_NAME,
  getRpcReadEndpoint,
  getRpcWriteEndpoints,
} from "../utils/env/env";
import { BLOCKHASH_COMMITMENT } from "../utils/solana/rpc";
import { RPC_ENDPOINT_URL_MAGICBLOCK } from "../sdk/constants";
import {
  getChainName,
  getPlatformGames,
  getPlatformTokenMetaByPubkey,
  getPlatformTokens,
} from "../utils/config/utils";
import { ICasinoToken } from "../types/token";
import { IPlatformGame } from "../types/game";
import { ENABLED_NETWORKS } from "../constants/chains";

export enum PriorityFeeLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  MID_LEVEL = 'MID_LEVEL',
  HIGH = 'HIGH'
}

export const showFeeNetworks = [APP_NETWORK_TYPE.SONIC];
export const showAutoSigningNetworks = [APP_NETWORK_TYPE.SONIC];

export interface IRecentBlockhash {
  blockhash: string;
  lastValidBlockHeight: number;
}

export interface INetworkContext {
  chain: APP_NETWORK_TYPE;
  chainDisplayName: string;
  setChain: React.Dispatch<React.SetStateAction<APP_NETWORK_TYPE | undefined>>;
  network: NetworkType;
  networkUrl: string;
  setNetworkUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
  client: Connection | undefined;
  erClient: Connection | undefined;
  recentBlockhash: IRecentBlockhash;
  networkCounter: number;
  platformTokens: ICasinoToken[];
  platformTokenMetaByPubkey: Map<string, ICasinoToken>;
  platformGames: IPlatformGame[];
  writeEndpoints: string[];
  solanaClient: Connection;
  uptoDateWithChain: boolean;
  priorityFeeLevel: PriorityFeeLevel;
  setPriorityFeeLevel: React.Dispatch<React.SetStateAction<PriorityFeeLevel>>;
}

export const NetworkContext = createContext<INetworkContext>(
  {} as INetworkContext
);

interface Props {
  children: any;
}

export const NetworkProvider = ({ children }: Props) => {
  // MB OR SONIC
  const [chain, setChain] = useLocalStorage("zeebit-app-chain", APP_NETWORK);
  const [priorityFees, setPriorityFees] = useLocalStorage("zeebit-priority-fee-level", PriorityFeeLevel.MID_LEVEL)

  // AFTER THE CHAIN IS UPDATED WE ALSO NEED TO UPDATE THE DEPENDANT VARIABLES
  const [uptoDateWithChain, setUptoDateWithChain] = useState(false)
  const lastLoadedChainRef = useRef<APP_NETWORK_TYPE>()

  // DEVNET OR MAINNET
  const [network, setNetwork] = useState<NetworkType>(defaultNetworkType);

  // RPC URL, RPC URLS FOR CHAIN AND NETWORK
  const [networkUrl, setNetworkUrl] = useLocalStorage(
    `rpc-endpoint-${ENV_NAME}`,
    getRpcReadEndpoint(APP_NETWORK)
  );
  const [writeEndpoints, setWriteEndpoints] = useLocalStorage(
    `rpc-write-endpoint-${ENV_NAME}`,
    getRpcWriteEndpoints(APP_NETWORK)
  );

  // ALL VARIABLE RELYING ON CHAIN (MB, SONIC)
  const [client, setClient] = useState<Connection>()
  const [platformTokens, setPlatformTokens] = useState<ICasinoToken[]>()
  const [platformTokenMetaByPubkey, setPlatformTokenMetaByPubkey] = useState<Map<string, ICasinoToken>>()
  const [platformGames, setPlatformGames] = useState<IPlatformGame[]>()
  const [chainDisplayName, setChainDisplayName] = useState<string>()

  // ENDURE THE CHAIN IS ALLOWED, SET NETWORK URL AND WRITE ENDPOINTS
  useEffect(() => {
    if (chain == null) {
      return;
    }

    const allowedChain = ENABLED_NETWORKS.includes(chain) ? chain : ENABLED_NETWORKS[0]

    if (chain != allowedChain) {
      setUptoDateWithChain(false)
      setChain(allowedChain)
    } else if (lastLoadedChainRef.current == allowedChain) {
      return
    }

    // UPDATE ALL VARIABLES RELYING ON CHAIN

    const newNetworkUrl = getRpcReadEndpoint(allowedChain)

    // NETWORK URLS
    setNetworkUrl(newNetworkUrl);
    setWriteEndpoints(getRpcWriteEndpoints(allowedChain));
    setClient(new Connection(newNetworkUrl, {
      commitment: "confirmed",
    }))
    setPlatformTokens(getPlatformTokens(defaultNetwork, allowedChain))
    setPlatformTokenMetaByPubkey(getPlatformTokenMetaByPubkey(allowedChain))
    setPlatformGames(getPlatformGames(allowedChain))
    setChainDisplayName(getChainName(allowedChain))

    setUptoDateWithChain(true)
    lastLoadedChainRef.current = allowedChain
  }, [chain]);

  // GET RPC CONNECTION FOR NETWORK ON MB
  const erClient = useMemo(() => {
    try {
      return new Connection(RPC_ENDPOINT_URL_MAGICBLOCK, {
        commitment: "confirmed",
      });
    } catch (e) {
      console.warn("Issue loading the er client", e);
    }
  }, []);

  //SOLANA CLIENT USED FOR NFT STAKING
  const solanaClient = useMemo(() => {
    const baseClientUrl = getRpcReadEndpoint(APP_NETWORK_TYPE.SOLANA)

    return new Connection(baseClientUrl, {
      commitment: "processed",
    })
  }, [])

  const [recentBlockhash, setRecentBlockhash] = useState<IRecentBlockhash>();

  const intervalRef = useRef<number>();
  const clientRef = useRef<Connection>();
  const counterRef = useRef<number>(0);
  const [counter, setCounter] = useState<number>();

  // KEEP A RECENT BLOCKHASH
  useEffect(() => {
    async function loadRecentBlockhash() {
      try {
        // LOAD RECENT BLOCKHASH CONFIRMED/FINALIZED (PROCESSED CAN BE DROPPED...)
        const recentHash =
          await clientRef.current?.getLatestBlockhash(BLOCKHASH_COMMITMENT);
        setRecentBlockhash(recentHash);

        counterRef.current += 1;
        setCounter(counterRef.current);
      } catch (err) {
        console.warn("Error loading blockhash", err);
      }
    }

    if (client != null) {
      clientRef.current = client;
      loadRecentBlockhash();

      if (intervalRef.current != null) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }

      intervalRef.current = setInterval(() => {
        loadRecentBlockhash();
      }, 10000);
    }

    return () => {
      if (intervalRef.current != null) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    };
  }, [client]);

  return (
    <NetworkContext.Provider
      value={useMemo(
        () => ({
          network: network,
          networkUrl: networkUrl,
          client: client,
          erClient: erClient,
          recentBlockhash: recentBlockhash,
          networkCounter: counter,
          setNetworkUrl: setNetworkUrl,
          chain: chain,
          setChain: setChain,
          platformTokens: platformTokens,
          platformTokenMetaByPubkey: platformTokenMetaByPubkey,
          platformGames: platformGames,
          writeEndpoints: writeEndpoints,
          chainDisplayName: chainDisplayName,
          solanaClient: solanaClient,
          uptoDateWithChain: uptoDateWithChain, // FLAG TO KNOW ALL VARIABLES ARE UP TO DATE AFTER CHAIN SWITCH
          priorityFeeLevel: priorityFees,
          setPriorityFeeLevel: setPriorityFees
        }),
        [
          network,
          networkUrl,
          client,
          recentBlockhash,
          counter,
          setNetworkUrl,
          erClient,
          chain,
          platformTokens,
          platformTokenMetaByPubkey,
          platformGames,
          writeEndpoints,
          chainDisplayName,
          solanaClient,
          uptoDateWithChain,
          priorityFees,
          setPriorityFees
        ]
      )}
    >
      {children}
    </NetworkContext.Provider>
  );
};
