import React, { Fragment, useContext, useMemo, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useModal } from "react-modal-hook";
import { twMerge } from "tailwind-merge";
import Button from "../common/button/Button";
import FlameIcon from "../../assets/icons/flame.svg";
import { AggregatedBalancesContext, IMergedToken } from "../../contexts/AggregatedBalancesContext";
import { CasinoSwitch, CasinoSwitchSize } from "../common/switch/switch";
import { NumberType, formatZeebitNumber } from "../../utils/currency/formatting";
import Icon from "../common/icon/Icon";
import { UserPreferenceContext, BalanceContext } from "../../contexts";
import { SessionAuthorityContext } from "../../contexts/SessionAuthorityContext";
import { WrappedWalletContext } from "../../contexts";
import { AutoSigningNeedsMoreSolModal } from "../../modals/auto-sign/AutoSigningNeedsMoreSolModal";
import { Scrollbar, Tooltip } from "../common";
import Spinner from "../common/spinner/Spinner";
import { Image } from "../zeebros-page/commonComponents";

interface IWalletDropdownProps {
  setWalletModalOpen: Function;
  openGasModal: Function;
  className?: string;
}

export const WalletDropdown = ({ setWalletModalOpen, openGasModal, className }: IWalletDropdownProps) => {
  // NEED THE LIST OF TOKENS
  const { mergedTokens } = useContext(AggregatedBalancesContext);
  const { selectedTokenMeta, setSelectedTokenMeta } = useContext(BalanceContext);
  const { isTokenValuesInUSD, setIsTokenValuesInUSD } = useContext(UserPreferenceContext);
  const { allowsAutoSigning, setAllowsAutoSigning, lamportBalance } = useContext(SessionAuthorityContext);
  const { isWeb3AuthWallet } = useContext(WrappedWalletContext);
  const { allowsAutoDeposit, setAllowsAutoDeposit } = useContext(SessionAuthorityContext);

  const solTokenMeta = mergedTokens?.find((token) => {
    return token?.context?.symbol == "SOL";
  });

  const selectedToken = useMemo(() => {
    if (selectedTokenMeta == null || mergedTokens == null) {
      return;
    }

    const selectedTokenMintString = selectedTokenMeta.mint;

    return mergedTokens.find((token) => {
      return token?.context?.pubkey == selectedTokenMintString;
    });
  }, [selectedTokenMeta, mergedTokens]);

  const selectedFormattedCurrency = useMemo(() => {
    return formatZeebitNumber(
      selectedToken?.combined?.uiAmount || 0,
      NumberType.TOKEN_AMOUNT,
      undefined,
      selectedToken?.context?.decimals || 9,
    );
  }, [selectedToken]);

  const [hideZeroBalances, setHideZeroBalances] = useState(false);

  const balancesFiltered = useMemo(() => {
    return hideZeroBalances == false
      ? mergedTokens
      : mergedTokens?.filter((token) => {
        return token.wallet != null && token.wallet.basis > 0;
      });
  }, [mergedTokens, hideZeroBalances]);

  // TODO - CHECK WHY SVG BEING OVERWRITTEN BTC/USDC
  // const icon = <SVG src={token?.context?.imageDarkSvg || ''} width={16} height={16} />;
  const iconPng = (
    <Image url={selectedToken?.context?.imageDarkPng || ""} classes={{ wrapper: "h-6 w-6 rounded-full" }} />
  );

  const [showAutoSigningNeedsMoreSolModal, hideAutoSigningNeedsMoreSolModal] = useModal(
    ({ in: open }) => (
      <AutoSigningNeedsMoreSolModal
        visible={open}
        hideModal={() => {
          hideAutoSigningNeedsMoreSolModal();
        }}
      />
    ),
    []
  );

  const handleChangeAllowsAutoSigning = (isChecked) => {
    if (
      isChecked
      && (!lamportBalance || lamportBalance < 5000)
    ) {
      showAutoSigningNeedsMoreSolModal();
    } else {
      setAllowsAutoSigning(isChecked);
    }
  }

  return (
    <Menu
      as="div"
      className={twMerge(
        "relative z-20 flex items-start ring-0 gap-x-3 sm:-space-x-1",
        className || "",
      )}
    >
      <Menu.Button
        as="div"
        className={twMerge(
          "flex h-[42px] w-[165px] items-center justify-between rounded-md",
          "bg-gray-900 px-2 py-0 sm:w-[180px] sm:rounded-s-md cursor-pointer"
        )}
      >
        <div className="flex flex-col items-start justify-start">
          <div className="flex text-base items-center gap-1 font-semibold text-gray-50">
            {(selectedToken?.isUpdatingBalance || false) ? <Spinner size="xs" /> : null}
            <Tooltip content={"Play Balance"} tippyClassName="-mt-1.5">
              <>
                {
                  formatZeebitNumber(
                    (selectedToken?.playerToken?.playBalance || 0) / 10 ** (selectedToken?.context?.decimals || 6),
                    NumberType.TOKEN_AMOUNT,
                    9,
                    selectedToken?.context?.decimals
                  )
                }
              </>
            </Tooltip>
          </div>
          <div className="flex text-xs font-normal text-gray-300 -mt-1">
            <Tooltip content={"Connected Wallet Balance"} tippyClassName="-mt-1.5">
              <>
                {
                  formatZeebitNumber(
                    selectedToken?.wallet?.uiAmount || 0,
                    NumberType.TOKEN_AMOUNT,
                    9,
                    selectedToken?.context?.decimals
                  )
                }
              </>
            </Tooltip>
          </div>
        </div>
        <div>{iconPng}</div>
      </Menu.Button>
      <Button
        onClick={() => setWalletModalOpen(true)}
        variant="primary"
        size="md"
        className="hidden h-[42px] sm:block"
      >
        {
          !((solTokenMeta?.wallet?.uiAmount ?? 0) > 0 || (solTokenMeta?.playerToken?.playBalance ?? 0) > 0)
            ? "Funds"
            : !!selectedToken?.playerToken?.playBalance && selectedToken?.playerToken?.playBalance > 0
              ? "Wallet"
              : "Deposit"
        }
      </Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          as="div"
          className="absolute right-0 top-[50px] w-[90vw] outline-none ring-0 sm:-right-5.5 sm:w-[300px] sm:min-w-full"
        >
          <div className="flex w-full flex-col items-center gap-y-1.5 rounded-lg bg-gray-700 px-0 py-2 relative">
            <div className="flex w-full justify-between font-normal text-xs px-4 pt-2 text-gray-300">
              <div className="flex">TOKEN</div>
              <div className="flex">PLAY BALANCE / WALLET</div>
            </div>

            {/* CURRENCIES */}
            <Scrollbar
              className="flex w-[calc(100%_+_12px)] max-h-[134px] [&>.os-scrollbar-vertical]:right-4.5"
            >
              <div className="flex w-full flex-col items-start">
                {balancesFiltered?.map((token, index) => {
                  return (
                    <CurrencyLine
                      key={`${index}-currency`}
                      showBaseValue={isTokenValuesInUSD}
                      token={token}
                      onClick={() => {
                        setSelectedTokenMeta({
                          mint: token.context?.pubkey || "",
                          decimals: token.context?.decimals || 6,
                        });
                      }}
                    />
                  );
                })}
              </div>
            </Scrollbar>

            {/*/!* GAS METER *!/*/}
            {/*<div className="flex w-full px-3">*/}
            {/*  <GasMeter onClick={openGasModal} lamportBalance={solBalances?.native?.basis || 0} />*/}
            {/*</div>*/}

            {/* SLIDERS */}
            <div className="flex w-full flex-col items-center gap-y-2 px-4 pt-1.5 text-sm font-normal text-gray-300">
              {/* <div className="flex items-center justify-between self-stretch">
                <div>Amounts in USD</div>
                <div className="sm:hidden">
                  <CasinoSwitch
                    checked={isTokenValuesInUSD}
                    onChange={setIsTokenValuesInUSD}
                    size={CasinoSwitchSize.MEDIUM}
                    disabled={false}
                    label="Amounts in USD"
                  />
                </div>
                <div className="hidden sm:block">
                  <CasinoSwitch
                    checked={isTokenValuesInUSD}
                    onChange={setIsTokenValuesInUSD}
                    size={CasinoSwitchSize.TINY}
                    disabled={false}
                    label="Amounts in USD"
                  />
                </div>
              </div> */}
              <div className="flex items-center justify-between self-stretch">
                <div>Hide Zero Balances</div>
                <div className="sm:hidden">
                  <CasinoSwitch
                    checked={hideZeroBalances}
                    onChange={setHideZeroBalances}
                    size={CasinoSwitchSize.MEDIUM}
                    disabled={false}
                    label="Hide Zero Balances"
                  />
                </div>
                <div className="hidden sm:block">
                  <CasinoSwitch
                    checked={hideZeroBalances}
                    onChange={setHideZeroBalances}
                    size={CasinoSwitchSize.TINY}
                    disabled={false}
                    label="Hide Zero Balances"
                  />
                </div>
              </div>
              <div className="hidden flex items-center justify-between self-stretch">
                <div>Auto-Deposit</div>
                <div className="sm:hidden">
                  <CasinoSwitch
                    checked={allowsAutoDeposit}
                    onChange={setAllowsAutoDeposit}
                    size={CasinoSwitchSize.MEDIUM}
                    disabled={false}
                    label="Auto-Deposit"
                  />
                </div>
                <div className="hidden sm:block">
                  <CasinoSwitch
                    checked={allowsAutoDeposit}
                    onChange={setAllowsAutoDeposit}
                    size={CasinoSwitchSize.TINY}
                    disabled={false}
                    label="Auto-Deposit"
                  />
                </div>
              </div>
              {
                !isWeb3AuthWallet
                && (
                  <div className="flex items-center justify-between self-stretch">
                    <div>Auto-Signing</div>
                    <div className="sm:hidden">
                      <CasinoSwitch
                        checked={allowsAutoSigning}
                        onChange={handleChangeAllowsAutoSigning}
                        size={CasinoSwitchSize.MEDIUM}
                        disabled={false}
                        label="Auto-Signing"
                      />
                    </div>
                    <div className="hidden sm:block">
                      <CasinoSwitch
                        checked={allowsAutoSigning}
                        onChange={handleChangeAllowsAutoSigning}
                        size={CasinoSwitchSize.TINY}
                        disabled={false}
                        label="Auto-Signing"
                      />
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

interface IGasMeter {
  lamportBalance: number;
  onClick: Function;
}

const GasMeter = ({ lamportBalance, onClick }: IGasMeter) => {
  const hasGas = useMemo(() => {
    return lamportBalance > 0;
  }, [lamportBalance]);

  const lowGas = useMemo(() => lamportBalance / 5000 < 100, [lamportBalance]);

  const numberOfActions = useMemo(() => {
    const numberOfTxns = lamportBalance / 5000;

    if (numberOfTxns > 0 && numberOfTxns < 100) {
      return "<100";
    }

    if (numberOfTxns > 10000) {
      return "10k+";
    }

    if (numberOfTxns > 1000) {
      return `${Math.round(numberOfTxns / Math.pow(10, 3))}k`;
    }
    return `${Math.round(numberOfTxns)}`;
  }, [lamportBalance]);

  return (
    <Menu.Item
      onClick={onClick}
      as="div"
      className="flex min-w-full flex-col items-start gap-y-1.5 rounded-md bg-gray-600 px-3 py-2.5"
    >
      <div className="flex items-center gap-x-1 self-stretch">
        <div>
          <Icon weight="thin" size="md" inheritFill={true} icon={<FlameIcon />} />
        </div>
        <div className="text-sm">{hasGas ? (lowGas ? "Low gas" : "Enough Gas") : "No Gas"}</div>
      </div>

      {/* PROGRESS BAR */}
      <div
        className={`h-1.5 self-stretch rounded-full ${hasGas ? "bg-brand-mixed-gradient shadow-brand-pink-sm" : "bg-gray-900"
          }`}
      />

      <div className="flex items-start justify-between gap-x-2 self-stretch text-sm font-normal text-gray-300">
        <div className="whitespace-nowrap">Number of actions: </div>
        <div className="whitespace-nowrap">{numberOfActions}</div>
      </div>
    </Menu.Item>
  );
};

interface ICurrencyLine {
  token: IMergedToken;
  onClick: Function;
  showBaseValue: boolean;
}

const CurrencyLine = ({ token, onClick, showBaseValue }: ICurrencyLine) => {
  // const useBaseValue = showBaseValue;
  // const amountPreFormatting = useBaseValue ? token.wallet?.uiAmountBase : token.combined?.uiAmount;
  // const fomattedAmount = formatCurrency(amountPreFormatting || 0, token?.context?.decimals || 9, 8);
  const disabled = token.houseToken == null || token.houseToken.isActive == false;

  return (
    <Menu.Item
      as="div"
      onClick={disabled ? () => { } : onClick}
      className={twMerge(
        "flex pr-5 py-0.5 justify-between items-center self-stretch",
        disabled ? "" : "cursor-pointer",
        "hover:bg-gray-500",
      )}
    >
      <div className="flex items-center gap-x-2 ps-4 font-semibold text-base">
        {
          <>
            <div><img src={token?.context?.imageDarkPng || ""} className="h-6 w-6" /></div>
            <div>{token.context?.symbol}</div>
          </>
        }
      </div>
      {/*<div className={`${disabled ? "text-gray-400" : ""}`}>*/}
      {/*  {useBaseValue ? "$" : ""}*/}
      {/*  {fomattedAmount}*/}
      {/*</div>*/}
      <div className="flex flex-col items-end justify-end">
        <div className="flex text-base font-semibold text-gray-50 items-baseline gap-1.5">
          {token.isUpdatingBalance ? <Spinner size="xs" /> : null}
          {
            formatZeebitNumber(
              (token?.playerToken?.playBalance || 0) / 10 ** (token?.context?.decimals || 6),
              NumberType.TOKEN_AMOUNT,
              9,
              token?.context?.decimals
            )
          }
        </div>
        <div className="flex text-xs font-normal text-gray-300 -mt-1">{
          formatZeebitNumber(
            token?.wallet?.uiAmount || 0,
            NumberType.TOKEN_AMOUNT,
            9,
            token?.context?.decimals
          )
        }</div>
      </div>
    </Menu.Item>
  );
};
