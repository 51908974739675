import IconButton from "../common/button/IconButton";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { WrappedWalletContext, NavContext } from "../../contexts";
import { useModal } from "react-modal-hook";
import { FundsModal } from "../modals/FundsModal";
import { AddFundsAndPlayModal, IFundOrWithdrawModalTabs } from "../modals/AddFundsAndPlayModal";

export const BottomNav = () => {
  const navigate = useNavigate();
  const {
    leftPanelOpen,
    toggleLeftPanel,
    closeAllBars,
    toggleUserProfilePanel,
    userProfilePanelOpen,
  } = useContext(NavContext);
  const { loggedIn } = useContext(WrappedWalletContext);

  const [showFundsAndPlayModal, hideFundsAndPlayModal] = useModal(
    ({ in: open }) => (
      <AddFundsAndPlayModal
        visible={open}
        showModal={showFundsAndPlayModal}
        hideModal={() => {
          hideFundsAndPlayModal();
        }}
        walletModalSelectedTab={IFundOrWithdrawModalTabs.DEPOSIT}
      />
    ), []
  );

  const [showFundsModal, hideFundsModal] = useModal(
    ({ in: open }) => (
      <FundsModal
        visible={open}
        hideModal={hideFundsModal}
        showFundsAndPlayModal={showFundsAndPlayModal}
      />
    ), []
  );

  return (
    <div className="z-40 flex fixed md:hidden w-full bottom-0 h-top-bar-height bg-gray-800 items-center justify-between px-4 bottom-nav-shadow">
      <IconButton
        className="bg-gray-800"
        size="lg"
        iconName="hamburger_sm"
        onClick={() => {
          if (leftPanelOpen == true) {
            toggleLeftPanel(false);
          } else {
            closeAllBars();
            toggleLeftPanel(true);
          }
        }}
      />

      <IconButton
        className="bg-gray-800"
        size="lg"
        iconName="home"
        onClick={() => {
          closeAllBars();
          navigate(``);
        }}
      />
      {loggedIn ? (
        <IconButton
          onClick={() => {
            closeAllBars();
            showFundsModal();
          }}
          className="bg-gray-800"
          size="lg"
          iconName="wallet"
        />
      ) : (
        ""
      )}
      {loggedIn ? (
        <IconButton
          onClick={() => {
            if (userProfilePanelOpen == true) {
              toggleUserProfilePanel(false);
            } else {
              closeAllBars();
              toggleUserProfilePanel(true);
            }
          }}
          className="bg-gray-800"
          size="lg"
          iconName="settings"
        />
      ) : (
        ""
      )}
    </div>
  );
};
