import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AnchorProvider, Idl, Program } from "@coral-xyz/anchor";
import { NetworkContext } from "./NetworkContext";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";
import {
  NFT_STAKING_PROGRAM_PUBKEY,
  RANDOM_PROGRAM_PUBKEY,
  ZEEBIT_V2_PROGRAM,
} from "../sdk/constants";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import { IdlErrorCode } from "@project-serum/anchor/dist/cjs/idl";
import { getRpcReadEndpoint } from "../utils/env/env";
import { APP_NETWORK_TYPE } from "../types/chain";

import * as SONIC_ZEEBIT_V2 from '../../src/sdk/program-types/sonic_zeebit_v2'
import * as SOLANA_ZEEBIT_V2 from '../../src/sdk/program-types/solana_zeebit_v2'
import * as MB_ZEEBIT_V2 from '../../src/sdk/program-types/mb_zeebit_v2'
import * as NFT_STAKING from '../../src/sdk/program-types/nft_staking'
import { SwitchboardProgram } from "@switchboard-xyz/solana.js";
import { loadSwitchboardProgram } from "../utils/nft-satking-v1/utils";

export interface IProgramContext {
  meta: IProgramMeta | undefined;
  isUptoDateWithChain: boolean;
}
export interface IProgramMeta {
  anchorProvider: AnchorProvider | undefined;
  zeebitV2Program: Program | undefined;
  zeebitV2ErProgram: Program | undefined;
  randomnessProgram: Program | undefined;
  errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>;
  switchboardProgram: SwitchboardProgram | undefined;
  nftStakingProgram: Program | undefined;
}

export const ProgramContext = createContext<IProgramContext>({} as IProgramContext);

interface Props {
  children: any;
}

const toProgramIdlErrorByCode = (program: Program<Idl> | undefined): Map<number, IdlErrorCode> => {
  return (
    program?.idl?.errors?.reduce((result, item) => {
      result.set(item.code, item);

      return result;
    }, new Map<number, IdlErrorCode>()) || new Map()
  );
};

export const ProgramProvider = ({ children }: Props) => {
  const { client, erClient, chain, uptoDateWithChain } = useContext(NetworkContext);

  const [isUptoDateWithChain, setIsUptoDateWithChain] = useState<boolean>(false)
  const [loadedChain, setLoadedChain] = useState<APP_NETWORK_TYPE>()

  // META SHOULD ONLY BE UPDATED WHEN THE CHAIN IS IN SYNC
  const [meta, setMeta] = useState<IProgramMeta>();

  useEffect(() => {
    async function loadProgramMeta(bseClient: Connection, erCli: Connection) {
      try {
        const solanaClientUrl = getRpcReadEndpoint(APP_NETWORK_TYPE.SOLANA)
        const solanaClient = new Connection(solanaClientUrl, {
          commitment: "processed",
        })

        // PROVIDER FOR SOLANA SPECIFICALLY
        const solanaProvider = new AnchorProvider(solanaClient, new NodeWallet(new Keypair()), {
          commitment: solanaClient.commitment,
          preflightCommitment: solanaClient.commitment,
          skipPreflight: false,
        });

        // PROVIDER FOR SONIC OR MB BASE
        const provider = new AnchorProvider(bseClient, new NodeWallet(new Keypair()), {
          commitment: bseClient.commitment,
          preflightCommitment: bseClient.commitment,
          skipPreflight: false,
        });
        // PROVIDER FOR MB
        const erProvider = new AnchorProvider(erCli, new NodeWallet(new Keypair()), {
          commitment: erCli.commitment,
          preflightCommitment: erCli.commitment,
          skipPreflight: false,
        });

        // CHECK WHICH IDL TO USE...
        const IDL_TO_USE = chain == APP_NETWORK_TYPE.MB_AND_SOLANA ? SOLANA_ZEEBIT_V2.IDL : SONIC_ZEEBIT_V2.IDL

        // PROGRAM LOADED WITH BASE CLIENT AND ER CLIENT
        const zeebitV2Program = new Program(IDL_TO_USE, ZEEBIT_V2_PROGRAM, provider)
        const zeebitV2ErProgram = new Program(MB_ZEEBIT_V2.IDL, ZEEBIT_V2_PROGRAM, erProvider)

        // SOLANA PROVIDER USED FOR RANDOMNESS, NFT STAKING, AND SWITCHBOARD
        const randomnessPro = await loadProgram(solanaProvider, RANDOM_PROGRAM_PUBKEY);
        const nftStakingProgram = new Program(NFT_STAKING.IDL, NFT_STAKING_PROGRAM_PUBKEY, solanaProvider);

        const errorByCodeByProgram = [zeebitV2Program, randomnessPro].reduce(
          (result, item) => {
            if (item != null) {
              result.set(item?.programId.toString(), toProgramIdlErrorByCode(item));
            }

            return result;
          },
          new Map<string, Map<number, IdlErrorCode>>(),
        );
        const switchboardProgram = await loadSwitchboardProgram(solanaClient);

        setMeta({
          anchorProvider: provider,
          randomnessProgram: randomnessPro,
          zeebitV2Program: zeebitV2Program,
          zeebitV2ErProgram: zeebitV2ErProgram,
          errorByCodeByProgram: errorByCodeByProgram,
          switchboardProgram: switchboardProgram,
          nftStakingProgram: nftStakingProgram,
        });
        setLoadedChain(chain)
      } catch (e) {
        console.warn(`Issue loading the program meta.`, e);
      }
    }

    async function loadProgram(provider: AnchorProvider, programPubkey: PublicKey) {
      try {
        const idl = await Program.fetchIdl(programPubkey, provider);
        const program = new Program(idl, programPubkey, provider);

        return program;
      } catch (e) {
        console.warn(`Issue loading program.`, { e }, programPubkey.toString());
      }
    }

    if (client == null || erClient == null || uptoDateWithChain == false) {
      return;
    }

    loadProgramMeta(client, erClient);
  }, [client, erClient, uptoDateWithChain]);

  // FLAG SO WE KNOW CONTEXT BEHIND
  useEffect(() => {
    if (loadedChain != chain && isUptoDateWithChain == true) {
      setIsUptoDateWithChain(false)
    } else if (loadedChain == chain && isUptoDateWithChain == false) {
      setIsUptoDateWithChain(true)
    }
  }, [chain, meta, loadedChain])

  return (
    <ProgramContext.Provider
      value={useMemo(
        () => ({
          meta: meta,
          isUptoDateWithChain: isUptoDateWithChain
        }),
        [meta, isUptoDateWithChain],
      )}
    >
      {children}
    </ProgramContext.Provider>
  );
};
