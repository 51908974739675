function gtag(){
    window.dataLayer.push(arguments)
};

export const sendWalletConnected = (meta: object) => {
    if (!!window && !!window.dataLayer) {
        gtag('event', 'wallet_connected', meta)
    } else {
        console.warn(`DATA LAYER NOT DEFINED`)
    }
}

export const sendBetPlaced = (meta: object) => {
    if (!!window && !!window.dataLayer) {
        gtag('event', 'bet_placed', meta)
    } else {
        console.warn(`DATA LAYER NOT DEFINED`)
    }
}

export const sendDepsosit = (meta: object) => {
    if (!!window && !!window.dataLayer) {
        gtag('event', 'player_deposit', meta)
    } else {
        console.warn(`DATA LAYER NOT DEFINED`)
    }
}

export const sendWithdrawal = (meta: object) => {
    if (!!window && !!window.dataLayer) {
        gtag('event', 'player_withdrawal', meta)
    } else {
        console.warn(`DATA LAYER NOT DEFINED`)
    }
}

export const sendReferredBy = (meta: object) => {
    if (!!window && !!window.dataLayer) {
        gtag('event', 'referral_id', meta)
    } else {
        console.warn(`DATA LAYER NOT DEFINED`)
    }
}