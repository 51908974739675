import { sha256 } from 'js-sha256';
import * as base58 from "bs58";
import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";

import NftStaking from "./nftStaking";

export default class StakeReceipt {

    private _nftStakingInstance: NftStaking;
    private _state: any;
    private _publicKey: PublicKey;

    constructor( 
        nftStakingInstance: NftStaking,
        pubkey: PublicKey,
    ) {
        this._nftStakingInstance = nftStakingInstance;
        this._publicKey = pubkey;
    };

    static async tryLoad(
        nftStakingInstance: NftStaking,
        mintPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const stakeReceiptPubkey = nftStakingInstance.deriveStakeReceiptAccountPubkey(mintPubkey);
        const state = await nftStakingInstance.program.account.stakeReceipt.fetchNullable(stakeReceiptPubkey, commitmentLevel);
        if (state) {
            const stakeReceipt = new StakeReceipt(
                nftStakingInstance,
                stakeReceiptPubkey,
            );
            stakeReceipt._state = state;
            return stakeReceipt;
        } else {
            return null
        }
    }

    static loadFromBuffer(
        nftStakingInstance: NftStaking,
        pubkey: PublicKey,
        accountBuffer: Buffer
    ) {
        const state = nftStakingInstance.program.coder.accounts.decode(
            "stakeReceipt",
            accountBuffer
        );
        const stakeReceipt = new StakeReceipt(
            nftStakingInstance,
            pubkey,
        );
        stakeReceipt._state = state;
        return stakeReceipt;
    }

    static deriveStakeReceiptDiscriminator() {
        return Buffer.from(sha256.digest("account:StakeReceipt")).subarray(0, 8);
    }

    deriveStakeReceiptAccountPubkey(
        tokenMintPubkey: PublicKey,
    ): PublicKey {
        return this.nftStakingInstance.deriveStakeReceiptAccountPubkey(tokenMintPubkey);
    };

    static async getStakeReceiptsForOwner(
        nftStakingInstance: NftStaking,
        ownerPubkey: PublicKey
    ): Promise<StakeReceipt[]> { 
        const stakeReceiptPubkeysAndBuffers = await nftStakingInstance.program.provider.connection.getProgramAccounts(
            nftStakingInstance.program.programId,
            {
              filters: [
                {
                  memcmp: {
                    offset: 0, // Anchor account discriminator for Player type
                    bytes: base58.encode(StakeReceipt.deriveStakeReceiptDiscriminator()),
                  },
                },
                {
                  memcmp: {
                    offset: 8, // 8 (discriminator)
                    bytes: base58.encode(nftStakingInstance.mainPubkey.toBuffer()),
                  },
                },
                {
                  memcmp: {
                      offset: 40, // 8 (discriminator) + 32 (main)
                      bytes: base58.encode(ownerPubkey.toBuffer()),
                  },
                },
              ],
            },
        );
        return stakeReceiptPubkeysAndBuffers.map((pnb)=>(
            StakeReceipt.loadFromBuffer(
                nftStakingInstance,
                pnb.pubkey,
                pnb.account.data
            )
        ));
    }

    get nftStakingInstance() {
        return this._nftStakingInstance
    }

    get id() {
        return this._state.nftId
    }

    get mintPubkey() {
        return this._state.nftMint
    }
    get publicKey() {
        return this._publicKey
    }
    get avatarApplied () {
        return this._state.verifiedAvatar
    }
    get rakebackBoostApplied () {
        return this._state.rakebackBoostApplied
    }
    get balanceDrawndown () {
        return this._state.balanceDrawndown
    }
}

