import { FC, ReactNode, useRef, useState } from "react";
import IconFont from "../iconFont/IconFont";
import { twMerge } from "tailwind-merge";
import Tippy from "@tippyjs/react";
import "./style.css";
import { Scrollbar } from "../scrollbar";

export type DropdownOption = {
  [key: string]: ReactNode | object | string;
  value: string | object;
  key?: string;
  label: string | ReactNode;
  disable?: boolean;
};
export interface DropdownInputProps {
  disabled?: boolean;
  options: DropdownOption[];
  selectedValue?: string | object;
  defaultLabel?: string | React.ReactNode;
  selectedLabel?: string | React.ReactNode;
  classes?: {
    button?: string;
    dropdown?: string;
    dropdownItem?: string;
  };
  placeHolder?: string;
  onSelect: (value: string | object) => void;
}

const DropdownInput: FC<DropdownInputProps> = ({
  options,
  selectedValue,
  defaultLabel,
  selectedLabel,
  classes,
  disabled,
  placeHolder = "Select",
  onSelect,
}) => {
  const [isDropDownVisible, setDropDownVisible] = useState(false);
  const ref = useRef<HTMLButtonElement | null>();

  const dropdownItem = (option: DropdownOption) => (
    <button
      key={option.key || option.value}
      className={twMerge(
        "flex w-full rounded-lg bg-gray-500 p-1.5 hover:bg-gray-400",
        option.disable ? "cursor-not-allowed" : "cursor-pointer",
        classes?.dropdownItem,
      )}
      onClick={() => {
        if (!option.disable) {
          onSelect(option.value);
          setDropDownVisible(false);
        }
      }}
    >
      {option.label}
    </button>
  );

  const dropDownContent = options ? (
    <div
      className={twMerge("flex flex-col rounded-lg bg-gray-500 max-h-[300px]", classes?.dropdown)}
      style={{ minWidth: `${ref?.current?.offsetWidth || 0}px` }}
    >
      <Scrollbar vertical>
        {options.map(dropdownItem)}
      </Scrollbar>
    </div>
  ) : null;

  const selectedOption = () => {
    if (!selectedValue) return;
    return options.find((option) => {
      if (typeof selectedValue == "string") {
        return option.value === selectedValue;
      } else {
        return JSON.stringify(option.value) == JSON.stringify(selectedValue)
      }
    });
  };
  // console.log({ selectedOption: selectedOption() });
  // console.log({ selectedLabel });
  // console.log({ selectedValue });
  if (!options) return;
  return (
    <Tippy
      className="DropdownInput"
      content={dropDownContent}
      visible={isDropDownVisible}
      onClickOutside={() => setDropDownVisible(false)}
      onHide={() => setDropDownVisible(false)}
      interactive={true}
      appendTo={document.body}
      placement="bottom-start"
      arrow={false}
      offset={[0, 8]}
    //   minWidth={`${ref?.current?.offsetWidth || 0}px`}
    >
      <button
        disabled={!options.length || disabled}
        className={twMerge(
          `flex items-center justify-between gap-3 rounded-lg border border-gray-300 bg-gray-600 p-3 hover:bg-gray-500
          disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-gray-600`,
          isDropDownVisible ? "bg-gray-500" : "",
          classes?.button,
        )}
        ref={ref}
        onClick={() => setDropDownVisible((prev) => !prev)}
      >
        {selectedValue
          ? selectedLabel
            ? selectedLabel
            : selectedOption()?.label
          : defaultLabel
            ? defaultLabel
            : options?.[0]?.label
              ? options?.[0]?.label
              : placeHolder}
        {options.length ? (
          <IconFont
            className={`transition-all ${isDropDownVisible ? "rotate-180" : ""}`}
            name="arrow_down"
          />
        ) : null}
      </button>
    </Tippy>
  );
};
export default DropdownInput;
