import { SideBarMobile } from "../left-nav/SideBarMobile";
import { SCROLLBAR_CLASSES } from "../../styles/commonClasses";
import { useContext, useMemo, ReactNode } from "react";
import { truncatePubkey } from "../../utils/string/string";
import { NavContext } from "../../contexts/NavContext";
import { useNavigate } from "react-router-dom";
import { WrappedWalletContext, NetworkContext } from "../../contexts";
import BarFooter from "../left-nav/BarFooter";
import { IconFont, IconName } from "../common";
import IconButton from "../common/button/IconButton";
import { useModal } from "react-modal-hook";
import ConnectionModal from "../modals/ConnectionModal";
import { networksData } from "../../constants/chains";
import { AddFundsAndPlayModal, IFundOrWithdrawModalTabs } from "../modals/AddFundsAndPlayModal";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import { twMerge } from "tailwind-merge";
import { IS_MAINNET } from "../../utils/solana/rpc";

interface IUserProfileMobile {
  panelOpen: boolean;
}

export function UserProfileMobile({ panelOpen }: IUserProfileMobile) {
  const { disconnect, walletPubkey } = useContext(WrappedWalletContext);
  const { toggleUserProfilePanel } = useContext(NavContext);
  const { chain } = useContext(NetworkContext);
  const { isCopiedToClipboard, copyToClipboard } = useCopyToClipboard("Successfully copied wallet public key to clipboard.");

  const [showConnectionModal, hideConnectionModal] = useModal(
    ({ in: open }) => (
      <ConnectionModal
        open={open}
        closeModal={hideConnectionModal}
      />
    ),
    []
  );

  const [showFundsAndPlayModal, hideFundsAndPlayModal] = useModal(
    ({ in: open }) => (
      <AddFundsAndPlayModal
        visible={open}
        showModal={showFundsAndPlayModal}
        hideModal={() => {
          hideFundsAndPlayModal();
        }}
        walletModalSelectedTab={IFundOrWithdrawModalTabs.DEPOSIT}
      />
    ), []
  );
  const showReferrals = IS_MAINNET == false || localStorage.getItem("zeebit-show-referrals") == "true"
  
  const navigate = useNavigate();
  const NAVIGATION: IMobileMenuItem[] = useMemo(() => {
    return [
      {
        iconName: "wallet",
        subtitle: "Wallet",
        title: <div className="flex gap-1 items-center">
          <div>{truncatePubkey(walletPubkey?.toString() || "", 4)}</div>

          <IconButton size="xs"
            iconName="copy"
            iconSize="sm"
            iconClass="text-gray-400"
            onClick={(e) => {
              e.stopPropagation();

              if (isCopiedToClipboard == true || walletPubkey == null) {
                return;
              }
              copyToClipboard(walletPubkey?.toString());
            }}
            className={twMerge(
              "text-gray-400",
              isCopiedToClipboard ? "" : "cursor-pointer",
            )}
            disabled={isCopiedToClipboard} />
        </div>,
        action: showFundsAndPlayModal,
        secondaryAction: () => {

        }
      },
      {
        iconName: "globe",
        subtitle: "Network",
        title: networksData[chain].name,
        action: showConnectionModal,
      },
      showReferrals == true ?
        {
          iconName: "transactions",
          title: "Transactions",
          action: () => {
            navigate(`/transactions`);
            toggleUserProfilePanel();
          }
        } : null,
      {
        iconName: "link",
        title: "Referrals",
        action: () => {
          navigate(`/referrals`);
          toggleUserProfilePanel();
        }
      },
      {
        iconName: "log_out2",
        title: "Sign Out",
        action: () => {
          disconnect();
          navigate(`/`);
          toggleUserProfilePanel();
        }
      },
      // {
      //   iconName: "calendar",
      //   name: "Rewards",
      //   onClick: () => {
      //     setProfileTab(ProfileTab.REWARDS_CALENDAR);
      //     navigate(`/profile`);
      //     toggleUserProfilePanel();
      //   },
      //   active: true,
      // },
      // {
      //   iconName: "link",
      //   name: "Referrals",
      //   onClick: () => {
      //     setProfileTab(ProfileTab.REFERRALS);
      //     navigate(`/profile`);
      //     toggleUserProfilePanel();
      //   },
      //   active: false,
      // },
      // {
      //   iconName: "settings",
      //   name: "Settings",
      //   onClick: () => {
      //     setProfileTab(ProfileTab.SETTINGS);
      //     navigate(`/profile`);
      //     toggleUserProfilePanel();
      //   },
      //   active: true,
      // },
    ];
  }, [walletPubkey, isCopiedToClipboard, chain]);

  return (
    <SideBarMobile sidebarOpen={panelOpen}>
      <nav className={`flex grow flex-col overflow-y-auto gap-y-5 justify-between${SCROLLBAR_CLASSES}`}>
        <ul role="list" className="flex flex-1 flex-col gap-y-2">
          {NAVIGATION.filter((nav) => !!nav).map((navigation, index) => {
            return (
              <MobileMenuItem
                {...navigation}
              />
            );
          })}

        </ul>
        <BarFooter
          sidebarOpen={true}
          className={`mt-5`}
          toggleLeftBar={() => { }}
          displayProfile={false}
          showNetworkStatus={true}
        />
      </nav>
    </SideBarMobile>
  );
}
interface IMobileMenuItem {
  iconName: IconName,
  title: string | ReactNode,
  subtitle?: string,
  action: () => void,
  secondaryAction?: () => void,
  secondaryIconName?: IconName,

}
const MobileMenuItem = ({ iconName, title, subtitle, secondaryIconName, action, secondaryAction }: IMobileMenuItem) => {
  return (
    <button className="h-[56px] font-bold flex justify-between items-center bg-gray-700 rounded-md px-3 py-2 text-start" onClick={action}>
      <div className="flex flex-col gap-0.5">
        {subtitle ? <div className="text-sm font-normal text-gray-400">{subtitle}</div> : null}
        <div className="flex gap-1 items-center">
          {title}
        </div>
      </div>
      <IconFont name={iconName} className="text-[24px] text-gray-400" />
    </button>
  )
}