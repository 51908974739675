import React, { FC } from 'react';
import { twMerge } from "tailwind-merge";

import { HomePageTableLoading } from "../../components/common/table/Table";

type PlaceholderType = "tab" | "box" | "table"

interface DynamicPreloaderProps {
  schema: {
    type: PlaceholderType;
    number?: number;
    heightClass?: string;
  }[]
}
export const DynamicPreloader: FC<DynamicPreloaderProps> = ({ schema }) => {
  return <div className='flex flex-col gap-5'>
    {schema.map((item) => {
      if (item.type == "table") {
        return <HomePageTableLoading numberRows={5} />
      }
      if (item.type == "box") {
        return <div className={twMerge("flex bg-animated-gradient-gray-700-gray-800 h-60 w-full rounded-md", item.heightClass)} />
      }
      if (item.type == "tab") {
        return <div className='flex gap-3'>{new Array(item.number || 1).fill(1).map((_) => (<div className="rounded bg-animated-gradient-gray-700-gray-800 p-3 w-28"><div className='opacity-0'>{"|"}</div></div>))}</div>
      }

    })}</div>
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full gap-x-3">
        {
          [1, 2, 3].map((item) => (
            <div
              key={item}
              className={twMerge(
                "flex bg-animated-gradient-gray-700-gray-800 h-auto w-full rounded-md",
                "md:w-[calc(50%-6px)] lg:w-[calc(33.33%-8px)] aspect-[2/1]"
              )}
            />
          ))
        }
      </div>
      <div className="flex justify-between h-8 mt-7">
        <div
          className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-24 rounded-md"
        />
        <div className="flex h-full gap-x-2">
          {
            [1, 2].map((item) => (
              <div key={item} className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-8 rounded-md" />
            ))
          }
        </div>
      </div>
      <div className="flex w-full mt-5 gap-x-3">
        {
          [1, 2, 3, 4, 5, 6].map((item) => (
            <div
              key={item}
              className={twMerge(
                "flex bg-animated-gradient-gray-700-gray-800 rounded-md",
                "w-[calc(40%)] sm:w-[calc(33.33%-8px)] md:w-[calc(25%-9px)]",
                "lg:w-[calc(20%-6.9px)] xl:w-[calc(16.67%-10px)] aspect-[4/5.5]"
              )}
            />
          ))
        }
      </div>
      <div className="flex justify-between h-8 mt-7">
        <div
          className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-36 rounded-md"
        />
        <div className="flex h-full gap-x-2">
          {
            [1, 2, 3].map((item) => (
              <div key={item} className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-24 rounded-md" />
            ))
          }
        </div>
      </div>
      <div className="flex justify-between mt-7">
        <HomePageTableLoading numberRows={5} />
      </div>
    </div>
  );
};